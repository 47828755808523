


















































































































































































































































































































































































































@import '~variables'

.alert-rule-main
  .alert-rule-form
    border-radius $border-radius-smooth
    background-color white
    .alert-rule-title
      flex-direction column
      border-top 0
      padding $space-2
    .alert-rule-description
      flex-direction column
      padding $space-2
    .alert-rule-enabled
      flex-direction row
      padding $space-2
      .toggle-switch-container
        padding-left 20px
    .profile-tile
      border-right none
    .resolve-tile
      border-right none
      padding $space-2
    .trigger-resolve
      .grid-tile
        .grid-tile__value
          width 100%
    .alert-rule-config
      flex-direction column
      padding $space-2
    .alert-rule-node-selector
      flex-direction column
      padding $space-2
    .alert-rule-selector
      flex-direction column
      padding $space-2
      pre
        color $dark
    .alert-rule-update-submit
      justify-content flex-end
      padding $space-2
    .q-select
      border 1px solid black
    .form-button
      text-transform uppercase
.o-label
  text-transform capitalize

.grid-title
  font-weight 700
  font-size $fs-h2
.capitalize
  text-decoration capitalize

