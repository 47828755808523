<template>
  <div class="container">
    <div class="inputContainer">
      <div class="inputSlot">
        <slot
          v-if="!isSaving"
          v-bind="{
            disabled,
            value: $v.workingValue.$model,
            update,
          }"
        >
          <!-- default if slot not provided: text input -->
          <input
            v-model="$v.workingValue.$model"
            :disabled="disabled"
            style="width: 100%"
          />
        </slot>
        <span v-if="isSaving">
          saving...
        </span>
      </div>
      <div
        v-if="isViewing"
        class="buttonsContainer"
      >
        <button v-if="isWriteable"
          class="button"
          @click="beginEditing"
        >
          <div class="fas fa-edit item icon" />
        </button>
      </div>
      <div
        v-if="isEditing"
        class="buttonsContainer"
      >
        <button
          class="button"
          :disabled="$v.workingValue.$error"
          @click="save"
        >
          <div class="fas fa-check item icon" />
        </button>
        <button
          class="button"
          @click="cancel"
        >
          <div class="fas fa-times item icon" />
        </button>
      </div>
    </div>
    <div
      v-if="saveError !== null || $v.workingValue.$error"
      class="errorContainer"
    >
      <div v-if="validationRules.required && !$v.workingValue.required">
        Vous devez entrer une valeur
      </div>
      <div v-if="validationRules.between && !$v.workingValue.between">
        La valeur doit être comprise entre {{ $v.workingValue.$params.between.min }} et {{ $v.workingValue.$params.between.max }}
      </div>
      <div v-if="saveError !== null">
        {{ saveError }}
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';

enum InlineEditorState {
  Viewing,
  Editing,
  Saving,
}

export default Vue.extend({
  name: 'InlineEditor',
  props: {
    value: {
      type: String,
      required: true,
    },
    // async function to save value
    saveFn: {
      type: Function,
      required: true,
    },
    // vuelidate validation rules
    validationRules: {
      type: Object,
      default: () => ({}),
    },
    isWriteable: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    workingValue: null as any,
    state: InlineEditorState.Viewing,
    saveError: null,
  }),
  computed: {
    disabled(): boolean {
      return this.state !== InlineEditorState.Editing;
    },
    isViewing(): boolean {
      return this.state === InlineEditorState.Viewing;
    },
    isSaving(): boolean {
      return this.state === InlineEditorState.Saving;
    },
    isEditing(): boolean {
      return (
        this.state === InlineEditorState.Editing ||
        this.state === InlineEditorState.Saving
      );
    },
  },
  validations() {
    return {
      workingValue: {
        ...this.validationRules,
      },
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.workingValue = this.value;
      },
    },
    workingValue() {
      this.saveError = null;
    },
  },
  methods: {
    update(value: any) {
      this.$v.workingValue.$model = value;
    },
    beginEditing() {
      this.state = InlineEditorState.Editing;
    },
    async save() {
      this.state = InlineEditorState.Saving;
      this.saveError = null;
      try {
        await this.saveFn(this.workingValue);
        this.$emit('input', this.workingValue);
        this.state = InlineEditorState.Viewing;
      } catch (e) {
        this.saveError = e.message || e;
        this.$emit('error', e);
        this.state = InlineEditorState.Editing;
      }
    },
    cancel() {
      this.workingValue = this.value;
      this.state = InlineEditorState.Viewing;
      this.saveError = null;
    },
  },
});
</script>
<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inputContainer {
  display: flex;
  flex-direction: row;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
}

.inputSlot {
  flex-basis: 100%;
}

.errorContainer {
  color: red;
  text-align: left;
  background: white;
}
</style>
