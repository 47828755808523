@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.period-selector {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.period-selector .container {
  display: flex;
  flex-direction: row;
}
.period-selector .period {
  margin: 0 14px;
  color: #b4b2a9;
  cursor: pointer;
}
.period-selector .period:hover,
.period-selector .period--selected {
  position: relative;
  color: #000;
  font-weight: 700;
}
.period-selector .period:hover:after,
.period-selector .period--selected:after {
  position: absolute;
  right: 0;
  bottom: -4px;
  left: 0;
  height: 2px;
  background-color: #000;
  content: '';
}
.period-selector .calendar-drawer {
  position: absolute;
  top: 23px;
  right: 100%;
  transition: all 0.3s ease;
}
@media screen and (max-width: 500px) {
  .period-selector .calendar-drawer {
    top: 170px;
    right: 0;
    left: 0;
    z-index: 100;
    margin: 0 auto;
  }
}
.period-selector .calendar-drawer-alert {
  position: absolute;
  top: 100px;
  right: 45%;
  transition: all 0.3s ease;
}
@media screen and (max-width: 500px) {
  .period-selector .calendar-drawer-alert {
    top: 170px;
    right: 0;
    left: 0;
    z-index: 100;
    margin: 0 auto;
  }
}
.date-input-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  font-size: 1.1rem;
}
.date-input-container .control-time__date-input {
  padding: 4px;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
}
.date-input-container .date-input__chevron {
  transform: rotate(-90deg);
}
.date-input-container .date-input__hours {
  padding: 4px;
  color: #464749;
  font-weight: 300;
  cursor: pointer;
  user-select: none;
}
.date-input-container .selectedDrawer {
  height: 23px;
  border-radius: 4px;
  background-color: #000;
  color: #fff;
}
.date-input__chevron {
  margin: 0 14px;
  width: 20px;
  height: 20px;
  transform: rotate(-90deg);
}
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/*# sourceMappingURL=src/oapps/widgets/period-selector.css.map */