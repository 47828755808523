






















































































































@import '~variables'

.comments
  display flex
  flex-direction column
  border-radius $border-radius-smooth
  background-color white
  box-shadow $shadow-card
  .title
    padding $space-2
    border-bottom $grid-border
    text-transform uppercase
    font-weight 300
    font-size $fs-h1
    line-height 1
  .comments-list
    flex 1
    overflow-y auto
    padding $space-2
    max-height 200px
    .comment-item
      display flex
      flex-shrink 0
      flex-direction column
      margin-bottom 8px
      padding 4px 8px
      max-width 350px
      width fit-content
      border-radius $border-radius
      background-color lighten($grey, 70%)
      &--me
        margin-left auto
        background-color $dark
        color white
        .info
          color lighten($grey, 20%) !important
      .message
        margin-bottom 4px
        width 100%
        font-size $fs-h3
      .info
        color darken($grey, 20%)
        font-size $fs-little
        .info-author
          margin-right 8px
  .no-comment
    padding $space-2
    text-align center
    font-size $fs-h3

.send-comment
  display flex
  flex-shrink 0
  padding $space-2
  border-top $grid-border
  .send-comment__input
    flex 8
    margin-right 8px
    width 100%
  .comment-button
    display flex
    flex 1
    justify-content center
    align-items center
    text-transform uppercase
