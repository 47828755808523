






















































@import '~variables'

.device-form
  max-width 500px
  .device-name
    flex-direction column
    padding $space-2
    .device-value
      flex-direction row
      .item
        padding 2px
  .device-description
    flex-direction column
    padding $space-2
  .return
    display grid
    padding-top 10px
    width fit-content
    text-transform uppercase

.o-label
  text-transform capitalize

