










































































































@import '~variables'

.line-graph
  max-width 500px
  max-height 800px
