@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.ticket-view {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 16px;
  height: 100%;
}
.ticket-view .button-row {
  display: flex;
  flex-shrink: 0;
  align-items: baseline;
  padding: 0 16px 16px;
}
.ticket-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(9,30,66,0.31), 0 2px 4px -1px rgba(9,30,66,0.25);
}
.ticket-wrapper .ticket-title {
  padding: 16px;
  width: 100%;
  color: #373737;
  font-weight: 700;
  font-size: 1.6rem;
}
.ticket-actions {
  display: flex;
  padding: 16px;
  border-top: 1px solid #f4f4f4;
}
.ticket-actions .share-button {
  margin-right: 8px;
}
.ticket-actions .edit-button {
  border-color: #464749;
  background-color: #fff;
  color: #464749;
}
.ticket-actions .ticket-status {
  flex-shrink: 0;
  margin-left: auto;
}
.share-link-container {
  padding: 16px;
  border-top: 1px solid #f4f4f4;
}
.ticket-url {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 4px;
  background-color: #f4f4f4;
  color: #000;
  text-transform: none;
  font-weight: 400;
  font-size: 1.2rem;
}
.ticket-url .url {
  margin: 4px 16px;
  margin-right: 4px;
  color: #464749;
}
.ticket-url .copy-to-clipboard {
  margin-left: 8px;
  background-color: #ddd9d3;
  color: #464749;
  cursor: pointer;
}
.ticket-infos {
  border-bottom: 1px solid #f4f4f4;
}
.ticket-message {
  padding: 16px;
  border-bottom: 1px solid #f4f4f4;
}
.ticket-message .message-label {
  margin-bottom: 8px;
  color: #acadaf;
}
.ticket-message .form-textarea {
  width: 100%;
  outline: none;
  border: none;
  font-size: 1.3rem;
  resize: none;
}
.ticket-documents {
  padding: 16px;
}
.ticket-documents .files-list {
  display: flex;
  justify-content: flex-start;
}
.ticket-documents .files-list .file-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  padding: 16px;
  border: 1px solid #e9e8e5;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  color: #827f72;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  font-size: 0.9rem;
  transition: box-shadow 0.3s ease;
}
.ticket-documents .files-list .file-item--download {
  cursor: pointer;
}
.ticket-documents .files-list .file-item--download:hover {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.ticket-documents .files-list .file-item .cross {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.ticket-documents .input-files {
  position: absolute;
  z-index: -1;
  overflow: hidden;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
}
.ticket-documents .input-files + label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 5px;
  background-color: #e9e8e5;
  color: #827f72;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 100ms ease;
}
.ticket-documents .input-files + label .picto {
  margin-bottom: 8px;
}
.ticket-documents .input-files + label:hover {
  background-color: #cbc9c3;
  color: #fff;
  transition: all 100ms ease;
}
.ticket-documents .input-files + label:focus {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.ticket-documents .input-files + label .inputfile + label * {
  pointer-events: none;
}
.comments {
  margin-top: 16px;
}
.qrcode a {
  color: inherit;
  text-decoration: none;
}
.qrcode .btn {
  margin-bottom: 0;
  margin-top: 12px;
}
/*# sourceMappingURL=src/app/pages/ticket/views/ticket-view.css.map */