
























































































































@import '~variables'

.alert-detail
  display flex
  flex-direction row
  @media (max-width: 1000px)
    flex-direction column
  font-size $fs-h2
  gap 3px
  justify-content space-evenly
  .sidebar
    display flex
    flex-direction column
    min-width 300px
    max-width 400px
    @media (max-width: 1000px)
      max-width none
  .alert-data
    display flex
    flex-direction column
    margin auto
    background-color white
    max-width 900px
    width 100%
    min-width 700px
    @media (max-width: 1000px)
      max-width 730px
      min-width 300px

.message
  display grid
  font-size $fs-h1
  place-items center

.actions-container
  display flex
  flex-direction row
  gap 10px
  margin 0 0 10px 0
  .pointer
    cursor pointer
  .pointer>*
    margin-right 4px

.content-container
  height fit-content
  border-radius 3px

.loader
  max-width 150px
  width 20%
  gap 10px
  display flex
  flex-direction column
  text-align center

