@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.alert-simple {
  cursor: pointer;
  border-radius: 4px;
}
.alert-simple .label {
  color: #808080;
}
.alert-simple .strong {
  font-size: 1.1rem;
  font-weight: 600;
}
.alert-simple .field {
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.alert-simple .field:first-child {
  border-top: 0;
}
.alert-simple .field:last-child {
  border-right: 0;
}
.low {
  border: 4px solid #d3eaff;
}
.normal {
  border: 4px solid #fbe1be;
}
.high {
  border: 4px solid #f29096;
}
.resolved {
  border: 0;
  background-color: #dcdcdc;
}
.o-label {
  text-transform: capitalize;
  color: #787a7d;
  margin-bottom: 0;
}
.grid-wrapper {
  border-top: 1px solid #dfdfdf;
}
.grid-tile {
  border-right: 1px solid #dfdfdf;
}
.device {
  word-break: break-all;
}
/*# sourceMappingURL=src/app/pages/alert/components/alert-simple.css.map */