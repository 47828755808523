.device--edition {
  cursor: pointer;
  fill: #000;
}
.device--navigation {
  fill: #fff;
}
.device--selected {
  fill: #000;
}
.device--hovered {
  fill: #fff;
  stroke-width: 4px;
  stroke: #000;
}
.device--big {
  stroke-width: 30px;
}
/*# sourceMappingURL=src/app/components/devices/device-dot.css.map */