

























































.w-full
  width 100%
.loader
  padding 10px
  display flex
  flex-direction column
  justify-content center
  text-align center
  align-items center
  .oasis-loader
    max-width 50px
