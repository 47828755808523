



















































































































































@import '~variables'

.title
  font-weight 400
  font-size 20px
  margin 20px 0 20px 0

.content-container
  background-color white
  height fit-content
  border-radius 3px
  padding 20px
  .table
    padding 20px
    font-weight 600
    font-size $fs-h2
    box-shadow none
.pointer
  cursor pointer
td
  max-width 200px
  overflow hidden
  text-overflow ellipsis
