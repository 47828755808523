







































































































































































































































@import '~variables'

.period-selector
  display flex
  flex-direction column
  .container
    display flex
    flex-direction row
    padding 20px
    align-items center
    justify-content: center
  .period
    margin 0 14px
    color $grey
    cursor pointer
    &:hover, &--selected
      position relative
      color black
      font-weight 700
      &:after
        position absolute
        right 0
        bottom -4px
        left 0
        height 2px
        background-color black
        content ''
  .calendar
    position absolute
    right 45%
    transition all 0.3s ease
    @media screen and (max-width: 500px)
      top 170px
      right 0
      left 0
      z-index 100
      margin 0 auto
  .date-container
    display flex
    flex-direction row
    padding 20px
    align-items center
    justify-content: center
    .select-date
      margin-left 10px
      padding-right 10px
      padding-top 5px
    .calendar-icon
      margin-right 10px
      display flex
      height 100%
      font-size 25px
      cursor pointer
      &:hover
        color grey
    .drop-box
      cursor pointer
      padding-left 10px
    > div
      margin-right 10px

