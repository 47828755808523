



























































































































































































































































@import '~variables'

.pointer
  cursor pointer

.title
  font-weight 400
  margin $space-2
  width 100%
  font-size $fs-h1

.table
  height fit-content
  background-color $dash-light
  border-radius 3px
  box-shadow none

.table-control-left
  margin-right 20px
.table-control-right
  margin-left 20px

.long-text
  max-width 200px
  text-overflow ellipsis
  overflow hidden
.wrap-text
  max-width 300px
  overflow-wrap anywhere

.actions-container
  display flex
  flex-direction row
  gap 10px
  margin 20px 0 20px 0

