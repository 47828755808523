



































































@import '~variables'

.title
  font-weight 400
  font-size 20px
  margin 20px 0 20px 0
.actions-container
  display flex
  flex-direction row
  gap 10px
  margin 20px 0 20px 0

.content-container
  background-color white
  height fit-content
  border-radius 3px


