















































































@import '~variables'

.flex-container
  display flex
  flex-direction row
  gap 15px;
  @media (max-width: 1000px)
    flex-direction column

.flex-item
  margin 5px auto 20px auto
  align-items center
  justify-content center

.alert-rule-main
  min-width 400px
  max-width 800px
  margin-bottom 50px

.alerts
  padding 20px
  min-width 450px
  max-width 800px
  max-height 1200px
  border-radius $border-radius-smooth
  background-color white
  @media (max-width: 500px)
    min-width 400px
    max-height 800px

.actions-container
  display flex
  text-transform uppercase
  cursor pointer
  margin-bottom 5px
  .pointer>*
    margin-right 3px
