






































































































































































@import '~variables'

.nodes-group-main
  display flex
  justify-content center
  align-items center
  margin auto
  padding-top 1%
  .nodes-group-form
    .nodes-group-name
      flex-direction column
      padding $space-2
    .nodes-group-description
      flex-direction column
      padding $space-2
    .profile-tile
      border-right none
    .nodes-group-update-submit
      justify-content flex-end
      padding $space-2
    .q-select
      border 1px solid black
      box-shadow $shadow-elevation-1
    .form-button
      text-transform uppercase
.o-label
  text-transform capitalize

.content-container
  background-color white
  height fit-content
  border-radius 3px

