@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.text-field {
  display: flex;
  flex-direction: column;
}
.text-field .text-field__label {
  margin-bottom: 8px;
  font-size: 1.6rem;
}
.text-field .text-field__label--hint {
  margin-bottom: 0px;
}
.text-field .text-field__required {
  color: #e91e63;
  font-size: 1.6rem;
}
.text-field .text-field__hint {
  margin-bottom: 8px;
  color: #8a8686;
  font-size: 1rem;
}
.text-field .input-container {
  margin-bottom: calc(4px + 15px);
  font-size: 1.3rem;
}
.text-field .input-container--error {
  margin-bottom: 4px;
}
.text-field .text-field__error-message {
  color: #e91e63;
}
/*# sourceMappingURL=src/app/components/inputs/field.css.map */