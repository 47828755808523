
























































































































@import '~variables'

.floor-container
  width 100%
  height 100%
  text-align center
  transition:
    top 1s cubic-bezier(0.02, 0.63, 0.31, 1.04),
    left 1s cubic-bezier(0.02, 0.63, 0.31, 1.04),
    z-index 0.2s cubic-bezier(0.02, 0.63, 0.31, 1.04)
  &.active
    svg
      pointer-events all
  &.drag
    svg
      cursor move
  &--zoomed
    svg
      .space-name
        display initial !important
        transition all 100ms ease
        user-select none
  & >>> svg
    overflow visible !important
    margin auto
    transition transform 0.2s ease
    @media screen and (max-width: 500px)
      transition transform 0.1s linear
    .space
      transition fill 0.2s ease
      fill $silver
      stroke $dark
      stroke-width 0.2
      &.selected
        opacity 1
        fill white !important
    .wall
      pointer-events none
      fill #333
      stroke-width 0
    .door
      fill $silver
      stroke black
      stroke-width 0.5px
    .window
      fill darken($light, 5%)
      stroke @fill
