@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.period-selector {
  display: flex;
  flex-direction: column;
}
.period-selector .container {
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: center;
  justify-content: center;
}
.period-selector .period {
  margin: 0 14px;
  color: #b4b2a9;
  cursor: pointer;
}
.period-selector .period:hover,
.period-selector .period--selected {
  position: relative;
  color: #000;
  font-weight: 700;
}
.period-selector .period:hover:after,
.period-selector .period--selected:after {
  position: absolute;
  right: 0;
  bottom: -4px;
  left: 0;
  height: 2px;
  background-color: #000;
  content: '';
}
.period-selector .calendar {
  position: absolute;
  right: 45%;
  transition: all 0.3s ease;
}
@media screen and (max-width: 500px) {
  .period-selector .calendar {
    top: 170px;
    right: 0;
    left: 0;
    z-index: 100;
    margin: 0 auto;
  }
}
.period-selector .date-container {
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: center;
  justify-content: center;
}
.period-selector .date-container .select-date {
  margin-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
}
.period-selector .date-container .calendar-icon {
  margin-right: 10px;
  display: flex;
  height: 100%;
  font-size: 25px;
  cursor: pointer;
}
.period-selector .date-container .calendar-icon:hover {
  color: #808080;
}
.period-selector .date-container .drop-box {
  cursor: pointer;
  padding-left: 10px;
}
.period-selector .date-container > div {
  margin-right: 10px;
}
/*# sourceMappingURL=src/app/pages/report/components/period-range-selector.css.map */