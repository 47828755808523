




































































































































































































































































@import '~variables'

.row > div
  padding: 5px 5px
.no-padding
  padding 0

.details
  .tabs-container
    display flex
    flex-direction column
    max-width 800px
  .ticket-container
    background-color $light
    display flex
    justify-content center
    align-items center
    margin auto
    width 100%
    .ticket-main
      width 100%
      .ticket-form
        border none
  .plan-container
    display flex
    justify-content center
    align-items center
    .map-container
      height 100%
  .data-container
    display flex
    flex-direction column
    height 100%
    align-items center
    padding $space-2
    .periodSelector
      margin-left auto
      width fit-content
      margin 0 100px
    .date-info
      display flex
      justify-content center
      padding $space-1
      font-weight 300
      font-size $fs-h3
  .message
    display grid
    font-size $fs-h1
    place-items center

