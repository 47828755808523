



















































@import '~variables'

.drawer
  background-color black
  box-shadow $shadow-elevation-3
  color white
  &--white
    background-color white
    color black
  .drawer__arrow
    position absolute
    width 0px
    height 0px
    &--left
      left -15px
      border-width 15px 15px 15px 0
      border-style solid
      border-color transparent black transparent transparent
    &--left-white
      border-color transparent white transparent transparent
    &--right
      right -10px
      border-width 15px 0 15px 15px
      border-style solid
      border-color transparent transparent transparent black
    &--right-white
      border-color transparent transparent transparent white
