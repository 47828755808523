







































































.device
  &--edition
    cursor pointer
    fill black
  &--navigation
    fill white
  &--selected
    fill black
  &--hovered
    fill white
    stroke-width 4px
    stroke black
  &--big
    stroke-width 30px
