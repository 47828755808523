@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.alert-simple {
  margin: 5px;
}
.title {
  color: #373737;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2rem;
}
.action-container {
  display: flex;
  align-items: center;
  padding: 0 16px 16px;
}
.list-filters {
  margin-left: auto;
}
.list-filters .filter-item {
  text-transform: uppercase;
}
.list-filters button {
  outline: none;
  border: none;
  background: none;
  letter-spacing: 0.5px;
  font-weight: 400;
  font-size: 1rem;
  cursor: pointer;
}
.list-filters button:hover {
  text-decoration: underline;
}
.list-filters .isSelected {
  text-decoration: underline;
  font-weight: 900;
}
.alerts-list-container {
  display: flex;
  overflow: hidden;
  max-height: 950px;
}
.alerts-list-container .alerts-list {
  overflow-y: auto;
}
@media (max-width: 500px) {
  .alerts-list-container {
    max-height: 500px;
  }
}
.alerts-stats {
  margin: 10px auto 10px auto;
}
.alerts-stats .flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.alerts-stats .flex-tile {
  display: flex;
  border-radius: 4px;
  margin: 3px;
  justify-content: flex-center;
  border: 1px solid #000;
  text-align: center;
}
.alerts-stats .item {
  padding: 4px;
  display: flex;
  flex-direction: column;
}
.alerts-stats .label {
  margin-bottom: 0;
  text-transform: capitalize;
  color: #787a7d;
}
.strong {
  font-size: 1.1rem;
  font-weight: 600;
}
/*# sourceMappingURL=src/app/pages/alert-rule/components/alerts-of-rule.css.map */