







































































































































































































@import '~variables'

.reporting-dashboard
  display flex
  flex-direction column
  height 100%
  align-items center
  padding $space-2

.graph, .profile
  display flex
  justify-content center
  padding 20px

