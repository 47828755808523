





























































































































































































@import '~variables'

.alert-simple
  margin 5px
.title
  color $font-color
  text-transform uppercase
  font-weight 700
  font-size 2rem

.action-container
  display flex
  align-items center
  padding 0 $space-2 $space-2

.list-filters
  margin-left auto
  .filter-item
    text-transform uppercase
  button
    outline none
    border none
    background none
    letter-spacing 0.5px
    font-weight 400
    font-size $fs-body
    cursor pointer
    &:hover
      text-decoration underline
  .isSelected
    text-decoration underline
    font-weight 900

.alerts-list-container
  display flex
  overflow hidden
  max-height 950px
  .alerts-list
    overflow-y auto
  @media (max-width: 500px)
    max-height 500px

.alerts-stats
  margin 10px auto 10px auto
  .flex-wrapper
    display flex
    flex-wrap wrap
    justify-content center
  .flex-tile
    display flex
    border-radius $border-radius-smooth
    margin 3px
    justify-content flex-center
    border 1px solid black
    text-align center
  .item
    padding 4px
    display flex
    flex-direction column
  .label
    margin-bottom 0
    text-transform capitalize
    color lighten($dark, 20)

.strong
  font-size $fs-h3
  font-weight 600


