





























































































































































@import '~variables'

.table
  padding 20px
  font-weight 600
  font-size $fs-h2
  box-shadow none

.pointer
  cursor pointer

// needs deep selector because it's used in child q-table component
>>> .w-0
  width: 0

