@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.nodes-group-main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 1%;
}
.nodes-group-main .nodes-group-form .nodes-group-name {
  flex-direction: column;
  padding: 16px;
}
.nodes-group-main .nodes-group-form .nodes-group-description {
  flex-direction: column;
  padding: 16px;
}
.nodes-group-main .nodes-group-form .profile-tile {
  border-right: none;
}
.nodes-group-main .nodes-group-form .nodes-group-update-submit {
  justify-content: flex-end;
  padding: 16px;
}
.nodes-group-main .nodes-group-form .q-select {
  border: 1px solid #000;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.nodes-group-main .nodes-group-form .form-button {
  text-transform: uppercase;
}
.o-label {
  text-transform: capitalize;
}
.content-container {
  background-color: #fff;
  height: fit-content;
  border-radius: 3px;
}
/*# sourceMappingURL=src/app/pages/nodes-group/components/nodes-group-details-editor.css.map */