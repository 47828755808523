








































































































@import '~variables'

.bar-graph
  width 100%
