

































































@import '~variables'

.text-field
  display flex
  flex-direction column
  .text-field__label
    margin-bottom $space-1
    font-size $fs-h1
    &--hint
      margin-bottom 0px
  .text-field__required
    color $negative
    font-size $fs-h1
  .text-field__hint
    margin-bottom $space-1
    color $infos
    font-size $fs-body
  .input-container
    margin-bottom 'calc(%s + %s)' % ($space-little 15px)
    font-size $fs-h2
    &--error
      margin-bottom $space-little
  .text-field__error-message
    color $negative
