








































































































































































































































































































































































































































































@import '~variables'

$input-bg-color = $light

.ticket-main
  .ticket-form
    border-radius $border-radius-smooth
    background-color white
    box-shadow $shadow-card
    .ticket-title
      flex-direction column
      padding $space-2
    .scope-choices
      display flex
      align-items center
      height 100%
      font-size $fs-body
    .priority-tile
      border-right none
    .ticket-message
      flex-direction column
      padding $space-2
    .ticket-update-submit
      justify-content flex-end
      padding $space-2
      grid-gap: 10px;
    .q-select
      border 1px solid black
      box-shadow $shadow-elevation-1
    .form-button
      text-transform uppercase
.o-label
  text-transform capitalize

.files-form
  flex-direction column
  padding $space-2
  .files-list
    display flex
    flex-wrap wrap
    justify-content flex-start
    .file-item
      position relative
      display flex
      flex-direction column
      justify-content center
      align-items center
      margin-right 8px
      margin-bottom 8px
      padding 16px
      border 1px solid lighten($grey, 70%)
      border-radius 5px
      box-shadow $shadow-elevation-1
      color darken($grey, 30%)
      text-transform uppercase
      letter-spacing 0.5px
      font-weight 700
      font-size 0.9rem
      transition box-shadow 0.3s ease
      &--download
        cursor pointer
        &:hover
          box-shadow $shadow-elevation-2
      .cross
        position absolute
        top 5px
        right 5px
        cursor pointer
  .input-files
    position absolute
    z-index -1
    overflow hidden
    width 0.1px
    height 0.1px
    opacity 0
  .input-files + label
    display flex
    flex-direction column
    justify-content center
    align-items center
    padding $space-2
    border 1px solid black
    border-radius $border-radius-smooth
    box-shadow $shadow-elevation-1
    text-transform uppercase
    letter-spacing 0.5px
    font-size 0.9rem
    cursor pointer
    transition all 100ms ease
    .picto
      margin-bottom 8px
    &:hover
      box-shadow $shadow-elevation-2
    &:focus
      outline 1px dotted #000
      outline -webkit-focus-ring-color auto 5px
    .inputfile + label *
      pointer-events none

.ticket-more-actions
  margin-top $space-2
  background-color white
  .grid-header
    display flex
    align-items center
    padding $space-2
    .arrow-down
      margin-left auto
      cursor pointer
  .actions
    padding $space-2
    .delete-button
      margin auto
      width fit-content

.grid-title
  font-weight 700
  font-size $fs-h2
