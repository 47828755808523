



















































































































































































































































































































































@import '~variables'

.ticket-view
  display flex
  flex-direction column
  overflow-y auto
  padding $space-2
  height 100%
  .button-row
    display flex
    flex-shrink 0
    align-items baseline
    padding 0 $space-2 $space-2

// ticket card container
.ticket-wrapper
  display flex
  flex-direction column
  border-radius $border-radius-smooth
  background-color white
  box-shadow $shadow-card
  .ticket-title
    padding $space-2
    width 100%
    color $font-color
    font-weight 700
    font-size $fs-h1

.ticket-actions
  display flex
  padding $space-2
  border-top $grid-border
  .share-button
    margin-right $space-1
  .edit-button
    border-color $dark
    background-color white
    color $dark
  .ticket-status
    flex-shrink 0
    margin-left auto

.share-link-container
  padding $space-2
  border-top $grid-border

// ticket url copy
.ticket-url
  display flex
  justify-content center
  align-items center
  width fit-content
  border-radius $border-radius-smooth
  background-color $dash-light
  color black
  text-transform none
  font-weight 400
  font-size 1.2rem
  .url
    margin 4px 16px
    margin-right 4px
    color $dark
  .copy-to-clipboard
    margin-left $space-1
    background-color $silver
    color $dark
    cursor pointer

// ticket infos
.ticket-infos
  border-bottom $grid-border

// ticket message
.ticket-message
  padding $space-2
  border-bottom $grid-border
  .message-label
    margin-bottom $space-1
    color lighten($dark, 40)
  .form-textarea
    width 100%
    outline none
    border none
    font-size 1.3rem
    resize none

// ticket documents
.ticket-documents
  padding $space-2
  .files-list
    display flex
    justify-content flex-start
    .file-item
      position relative
      display flex
      flex-direction column
      justify-content center
      align-items center
      margin-right 8px
      padding 16px
      border 1px solid lighten($grey, 70%)
      border-radius 5px
      box-shadow $shadow-elevation-1
      color darken($grey, 30%)
      text-transform uppercase
      letter-spacing 0.5px
      font-weight 700
      font-size 0.9rem
      transition box-shadow 0.3s ease
      &--download
        cursor pointer
        &:hover
          box-shadow $shadow-elevation-2
      .cross
        position absolute
        top 5px
        right 5px
        cursor pointer
  .input-files
    position absolute
    z-index -1
    overflow hidden
    width 0.1px
    height 0.1px
    opacity 0
  .input-files + label
    display flex
    flex-direction column
    justify-content center
    align-items center
    padding 16px
    border-radius 5px
    background-color lighten($grey, 70%)
    color darken($grey, 30%)
    text-transform uppercase
    letter-spacing 0.5px
    font-weight 700
    font-size 0.9rem
    cursor pointer
    transition all 100ms ease
    .picto
      margin-bottom 8px
    &:hover
      background-color lighten($grey, 30%)
      color white
      transition all 100ms ease
    &:focus
      outline 1px dotted #000
      outline -webkit-focus-ring-color auto 5px
    .inputfile + label *
      pointer-events none

.comments
  margin-top $space-2
.qrcode
  a
    color inherit
    text-decoration none
  .btn
    margin-bottom 0
    margin-top 12px
