@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.ticket-status {
  display: flex;
  align-items: center;
  padding: 0;
  width: fit-content;
}
.ticket-status .state {
  padding: 4px;
  background-color: transparent;
  color: #8f9093;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 300;
  font-size: 0.8rem;
  cursor: pointer;
}
.ticket-status .state:hover,
.ticket-status .state--current {
  border-radius: 4px;
  background-color: #464749;
  color: #fff;
  font-weight: 700;
}
.separator-arrow {
  margin: 0 8px;
  transform: rotate(-90deg);
}
/*# sourceMappingURL=src/app/pages/ticket/components/ticket-status.css.map */