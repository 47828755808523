@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.flex-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
@media (max-width: 1000px) {
  .flex-container {
    flex-direction: column;
  }
}
.flex-item {
  margin: 5px auto 20px auto;
  align-items: center;
  justify-content: center;
}
.alert-rule-main {
  min-width: 400px;
  max-width: 800px;
  margin-bottom: 50px;
}
.alerts {
  padding: 20px;
  min-width: 450px;
  max-width: 800px;
  max-height: 1200px;
  border-radius: 4px;
  background-color: #fff;
}
@media (max-width: 500px) {
  .alerts {
    min-width: 400px;
    max-height: 800px;
  }
}
.actions-container {
  display: flex;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 5px;
}
.actions-container .pointer>* {
  margin-right: 3px;
}
/*# sourceMappingURL=src/app/pages/alert-rule/components/alert-rule-details-layout.css.map */