@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.alert-icon {
  font-size: 16px;
}
.pointer {
  cursor: pointer;
}
.list-filters .filter-item {
  text-transform: uppercase;
}
.list-filters button {
  outline: none;
  border: none;
  background: none;
  letter-spacing: 0.5px;
  font-weight: 400;
  font-size: 1rem;
  cursor: pointer;
}
.list-filters button:hover {
  text-decoration: underline;
}
.list-filters .isSelected {
  text-decoration: underline;
  font-weight: 900;
}
.emergency {
  font-size: 18px;
}
.emergency-low {
  color: #008000;
}
.emergency-normal {
  color: #ffa500;
}
.emergency-high {
  color: #f00;
}
.title {
  font-weight: 400;
  margin: 16px;
  width: 100%;
  font-size: 1.6rem;
}
.table {
  height: fit-content;
  background-color: #f4f4f4;
  border-radius: 3px;
  box-shadow: none;
}
.table-control-left {
  margin-right: 20px;
}
.table-control-right {
  margin-left: 20px;
}
.resolved {
  background-color: #dfdfdf;
}
/*# sourceMappingURL=src/app/pages/alert/components/alert-center.css.map */