@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.svg-logo__a {
  fill: none;
  stroke: #464749;
  stroke-width: 3.5px;
  stroke-dasharray: 225;
  stroke-linecap: round;
  stroke-dashoffset: 450px;
  animation: dash 1.5s ease forwards infinite;
}
.svg-logo__o {
  opacity: 1;
  fill: none;
  stroke: #464749;
  stroke-width: 3.5px;
}
@-moz-keyframes dash {
  to {
    stroke-dashoffset: 0px;
  }
}
@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 0px;
  }
}
@-o-keyframes dash {
  to {
    stroke-dashoffset: 0px;
  }
}
@keyframes dash {
  to {
    stroke-dashoffset: 0px;
  }
}
/*# sourceMappingURL=src/app/components/ui/oasis-loader.css.map */