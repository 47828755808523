.w-full {
  width: 100%;
}
.loader {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.loader .oasis-loader {
  max-width: 50px;
}
/*# sourceMappingURL=src/app/components/ui/FetchableData.css.map */