




























































































































































































































































@import '~variables'

.alert-icon
  font-size 16px
.pointer
  cursor pointer


.list-filters
  .filter-item
    text-transform uppercase
  button
    outline none
    border none
    background none
    letter-spacing 0.5px
    font-weight 400
    font-size $fs-body
    cursor pointer
    &:hover
      text-decoration underline
  .isSelected
    text-decoration underline
    font-weight 900

.emergency
  font-size 18px
.emergency-low
  color green
.emergency-normal
  color orange
.emergency-high
  color red

.title
  font-weight 400
  margin $space-2
  width 100%
  font-size $fs-h1

.table
  height fit-content
  background-color $dash-light
  border-radius 3px
  box-shadow none

.table-control-left
  margin-right 20px
.table-control-right
  margin-left 20px
.resolved
  background-color #dfdfdf
