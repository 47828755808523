<template>
  <form
    class="form"
    @submit.prevent="save"
  >
    <TextField
      v-model="data.title"
      :label="'Nom du rapport'"
      :placeholder="'Le nom du rapport'"
    />
    <TextField
      v-model="data.description"
      :label="'Description'"
      :placeholder="'Une description du rapport (optionnel)'"
    />
    <Field :label="'Groupes de données'">
      <NodesGroupSelector
        :initialNodesGroup="data.nodesGroupsIds"
        @input="updateNodesGroups"
      />
    </Field>
    <Field :label="'Activation'">
      <div class="fieldWithCheckboxesContainer">
        <div>
          <input
            id="enabled"
            v-model="data.enabled"
            type="checkbox"
          />
          <label for="enabled">
            Activer le rapport
          </label>
        </div>
      </div>
    </Field>
    <Field
      :label="'Fréquence de génération'"
    >
      <div class="fieldWithCheckboxesContainer">
        <div>
          <input
            id="daily"
            v-model="data.daily"
            type="checkbox"
          />
          <label for="daily">
            Quotidien
          </label>
        </div>
        <div>
          <input
            id="weekly"
            v-model="data.weekly"
            type="checkbox"
          />
          <label for="weekly">
            Hebdomadaire
          </label>
        </div>
        <div>
          <input
            id="monthly"
            v-model="data.monthly"
            type="checkbox"
          />
          <label for="monthly">
            Mensuel
          </label>
        </div>
        <div>
          <input
            id="yearly"
            v-model="data.yearly"
            type="checkbox"
          />
          <label for="yearly">
            Annuel
          </label>
        </div>
      </div>
    </Field>
    <div>
      <Button :type="'submit'">
        {{ buttonText }}
      </Button>
    </div>
  </form>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue';
import _clone from 'lodash/clone';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';

import Button from '@/app/components/inputs/button.vue';
import Field from '@/app/components/inputs/field.vue';
import TextField from '@/app/components/inputs/text-field.vue';

import NodesGroupSelector from '../../nodes-group/components/nodes-group-selector.vue';

import api from '@/services/api';

import { NodesGroup, ReportConfig, ReportConfigCreatePayload, ReportConfigUpdatePayload, ReportRecipient } from '@/types/api';
import { HttpMetadata, HttpMetadataDefaults } from '@/types/http';

// if we're creating, we have no id or authorUuid
type ReportConfigCreateEditData =
  Omit<ReportConfig, 'id' | 'authorUuid' | 'nodesGroups' | 'reportRecipients' | 'createdAt' | 'updatedAt'>
  & { id?: number, authorUuid?: string, nodesGroupsIds: number[], reportRecipientsUuids: string[] };

export default Vue.extend({
  name: 'CreateEditReportConfig',
  components: {
    Button,
    Field,
    TextField,
    NodesGroupSelector,
  },
  props: {
    reportConfigToEdit: {
      type: Object as PropType<ReportConfig | null>,
      default: null,
    },
  },
  data: (): {
    data: ReportConfigCreateEditData,
    meta: HttpMetadata,
  } => ({
    data: {
      title: '',
      description: '',
      enabled: true,
      daily: false,
      weekly: false,
      monthly: true,
      yearly: false,
      nodesGroupsIds: [],
      reportRecipientsUuids: [],
    },
    meta: {
      ...HttpMetadataDefaults,
    },
  }),
  computed: {
    isUpdate(): boolean {
      return !!this.reportConfigToEdit;
    },
    isCreate(): boolean {
      return !this.isUpdate;
    },
    buttonText(): string {
      if (this.isUpdate) {
        return 'Sauver les modifications';
      } else {
        return 'Créer un nouveau rapport';
      }
    }
  },
  created() {
    if (this.reportConfigToEdit !== null) {
      const nodesGroups = this.reportConfigToEdit.nodesGroups as NodesGroup[];
      const reportRecipients = this.reportConfigToEdit.reportRecipients as ReportRecipient[];
      this.data = {
        ..._pick(this.reportConfigToEdit, ['title', 'description', 'enabled', 'daily', 'weekly', 'monthly', 'yearly']),
        nodesGroupsIds: nodesGroups.map(n => n.id as number),
        reportRecipientsUuids: reportRecipients.map(r => r.recipientUuid),
      };
    }
    // hack until we have a recipient selector
    this.data.reportRecipientsUuids = [ this.$store.state.user.user.uuid ];
  },
  methods: {
    async save() {
      // TODO add validation checks
      this.meta.isLoading = true;

      try {
        if (this.isCreate) {
          const createPayload: ReportConfigCreatePayload = {
            ..._omit(this.data, ['id']),
          };

          const res = await api.reportConfig.create(createPayload);

          // update id
          this.data.id = res.data.id;
        } else {
          const updatePayload: ReportConfigUpdatePayload = {
            ..._omit(this.data, ['id']),
          };

          const res = await api.reportConfig.update(this.reportConfigToEdit!.id, updatePayload);
        }
        this.meta.lastFetch = new Date();
        this.$emit('saved', this.data);
      }
      catch (err: any) {
        // FIXME
        this.meta.error = err.message || this.$t('global.errors.generic');
        this.$emit('error', err);
      }
      this.meta.isLoading = false;
    },
    // FIXME the argument to NodesGroupsSelector should be a v-model !
    updateNodesGroups(val: number[]) {
      this.data.nodesGroupsIds = val;
    }
  }
});
</script>
<style scoped>
.form {
  padding: 20px;
}

.fieldWithCheckboxesContainer > div {
  padding: 5px;
}

.fieldWithCheckboxesContainer input[type="checkbox"] {
  margin: 0 10px 0 0;
}
</style>