@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.details {
  display: flex;
  justify-content: center;
}
.details .content {
  text-align: left;
  display: flex;
  background-color: #f3f1ef;
  flex-direction: column;
  gap: 1px;
}
.details .content .item {
  background-color: #fff;
  min-width: 200px;
  text-align: left;
  justify-content: left;
  padding: 15px;
}
.details .content .item .label {
  color: #acadaf;
  font-size: 14px;
  margin-bottom: 10px;
}
.details .content .item .high {
  background-color: #ff3d00;
  color: #fff;
}
.details .content .item .normal {
  background-color: #ffb800;
  color: #fff;
}
.details .content .item .low {
  background-color: #8dcc26;
  color: #fff;
}
.details .content .title {
  border-top: none;
  text-align: center;
  font-size: 1.6rem;
}
.details .history {
  text-align: center;
  padding: 50px;
}
.details .history .history-button {
  color: #fff;
  background: #074ee8;
  position: static;
  left: 12px;
  top: 7px;
}
.details .history .trigger {
  padding: 40px;
}
@media (max-width: 1000px) {
  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
/*# sourceMappingURL=src/app/pages/alert/components/alert-detail-sidebar.css.map */