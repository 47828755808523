










html, body.global
  font-size 12px
  font-family 'Lato'
