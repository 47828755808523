@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.actions-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 20px 0 20px 0 !important;
}
.title {
  font-weight: 400;
  font-size: 20px;
  margin: 20px 0 20px 0;
}
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-center {
  justify-content: center;
}
.p-10 {
  padding: 10px;
}
.p-20 {
  padding: 20px;
}
.mb-10 {
  margin-bottom: 10px;
}
.font-size-x-large {
  font-size: x-large;
}
.content-action {
  display: flex;
  margin: 20px 0 20px 0;
}
.content-container {
  background-color: #fff;
  height: fit-content;
  border-radius: 3px;
}
.content-container .table {
  padding: 20px;
  font-weight: 600;
  font-size: 1.3rem;
  box-shadow: none;
}
/*# sourceMappingURL=src/app/pages/report/ReportConfigView.css.map */