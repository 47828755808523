
















































































































































































@import '~variables'

.timepicker
  min-width 200px
  .timepicker__labels-row
    display flex
    justify-content center
    padding 5px 0
    border-bottom solid 1px $dark
    .timepicker__label
      width 100%
      text-align center
      text-transform uppercase
      font-size $fs-h3
  .picker-container
    display flex
    justify-content center
    width 100%
    height 100%
    .hours, .minutes
      display flex
      flex-direction column
      align-items center
      overflow auto
      padding 5px
      width 100%
      height 200px
      font-size 1.8rem
      @media screen and (max-width: 500px)
        justify-content start
    .hours
      @media screen and (max-width: 500px)
        overflow scroll
        height 280px
    .hour-input, .minute-input
      margin 5px 0
      padding 2px 0 2px 0
      width 100%
      text-align center
      cursor pointer
      &--selected
        border-radius 5px
        background-color white !important
        color black !important
      &:hover
        border-radius 5px
        background-color $dark
        color white
      &--disabled
        background-color black !important
        color $dark !important

::-webkit-scrollbar
  width 3px

::-webkit-scrollbar-track
  background #2f2f2f

::-webkit-scrollbar-thumb
  background #828282

::-webkit-scrollbar-thumb:hover
  background #b9b9b9
