@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.alert-rule-main .alert-rule-form {
  border-radius: 4px;
  background-color: #fff;
}
.alert-rule-main .alert-rule-form .alert-rule-title {
  flex-direction: column;
  border-top: 0;
  padding: 16px;
}
.alert-rule-main .alert-rule-form .alert-rule-description {
  flex-direction: column;
  padding: 16px;
}
.alert-rule-main .alert-rule-form .alert-rule-enabled {
  flex-direction: row;
  padding: 16px;
}
.alert-rule-main .alert-rule-form .alert-rule-enabled .toggle-switch-container {
  padding-left: 20px;
}
.alert-rule-main .alert-rule-form .profile-tile {
  border-right: none;
}
.alert-rule-main .alert-rule-form .resolve-tile {
  border-right: none;
  padding: 16px;
}
.alert-rule-main .alert-rule-form .trigger-resolve .grid-tile .grid-tile__value {
  width: 100%;
}
.alert-rule-main .alert-rule-form .alert-rule-config {
  flex-direction: column;
  padding: 16px;
}
.alert-rule-main .alert-rule-form .alert-rule-node-selector {
  flex-direction: column;
  padding: 16px;
}
.alert-rule-main .alert-rule-form .alert-rule-selector {
  flex-direction: column;
  padding: 16px;
}
.alert-rule-main .alert-rule-form .alert-rule-selector pre {
  color: #464749;
}
.alert-rule-main .alert-rule-form .alert-rule-update-submit {
  justify-content: flex-end;
  padding: 16px;
}
.alert-rule-main .alert-rule-form .q-select {
  border: 1px solid #000;
}
.alert-rule-main .alert-rule-form .form-button {
  text-transform: uppercase;
}
.o-label {
  text-transform: capitalize;
}
.grid-title {
  font-weight: 700;
  font-size: 1.3rem;
}
.capitalize {
  text-decoration: capitalize;
}
/*# sourceMappingURL=src/app/pages/alert-rule/components/alert-rule-details-editor.css.map */