@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.ticket-main .ticket-form {
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(9,30,66,0.31), 0 2px 4px -1px rgba(9,30,66,0.25);
}
.ticket-main .ticket-form .ticket-title {
  flex-direction: column;
  padding: 16px;
}
.ticket-main .ticket-form .scope-choices {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 1rem;
}
.ticket-main .ticket-form .priority-tile {
  border-right: none;
}
.ticket-main .ticket-form .ticket-message {
  flex-direction: column;
  padding: 16px;
}
.ticket-main .ticket-form .ticket-update-submit {
  justify-content: flex-end;
  padding: 16px;
  grid-gap: 10px;
}
.ticket-main .ticket-form .q-select {
  border: 1px solid #000;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.ticket-main .ticket-form .form-button {
  text-transform: uppercase;
}
.o-label {
  text-transform: capitalize;
}
.files-form {
  flex-direction: column;
  padding: 16px;
}
.files-form .files-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.files-form .files-list .file-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 16px;
  border: 1px solid #e9e8e5;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  color: #827f72;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  font-size: 0.9rem;
  transition: box-shadow 0.3s ease;
}
.files-form .files-list .file-item--download {
  cursor: pointer;
}
.files-form .files-list .file-item--download:hover {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.files-form .files-list .file-item .cross {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.files-form .input-files {
  position: absolute;
  z-index: -1;
  overflow: hidden;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
}
.files-form .input-files + label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border: 1px solid #000;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 100ms ease;
}
.files-form .input-files + label .picto {
  margin-bottom: 8px;
}
.files-form .input-files + label:hover {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.files-form .input-files + label:focus {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.files-form .input-files + label .inputfile + label * {
  pointer-events: none;
}
.ticket-more-actions {
  margin-top: 16px;
  background-color: #fff;
}
.ticket-more-actions .grid-header {
  display: flex;
  align-items: center;
  padding: 16px;
}
.ticket-more-actions .grid-header .arrow-down {
  margin-left: auto;
  cursor: pointer;
}
.ticket-more-actions .actions {
  padding: 16px;
}
.ticket-more-actions .actions .delete-button {
  margin: auto;
  width: fit-content;
}
.grid-title {
  font-weight: 700;
  font-size: 1.3rem;
}
/*# sourceMappingURL=src/app/pages/ticket/components/ticket-create-edit-form.css.map */