

























































@import '~variables'

.selector
  font-size $fs-h2
  & >>> .vue-treeselect__control
    border-color $black

