@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.drawer {
  background-color: #000;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  color: #fff;
}
.drawer--white {
  background-color: #fff;
  color: #000;
}
.drawer .drawer__arrow {
  position: absolute;
  width: 0px;
  height: 0px;
}
.drawer .drawer__arrow--left {
  left: -15px;
  border-width: 15px 15px 15px 0;
  border-style: solid;
  border-color: transparent #000 transparent transparent;
}
.drawer .drawer__arrow--left-white {
  border-color: transparent #fff transparent transparent;
}
.drawer .drawer__arrow--right {
  right: -10px;
  border-width: 15px 0 15px 15px;
  border-style: solid;
  border-color: transparent transparent transparent #000;
}
.drawer .drawer__arrow--right-white {
  border-color: transparent transparent transparent #fff;
}
/*# sourceMappingURL=src/app/components/ui/drawer.css.map */