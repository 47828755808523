
















@import '~variables'

.svg-logo__a
  fill none
  stroke $dark
  stroke-width 3.5px
  stroke-dasharray 225
  stroke-linecap round
  stroke-dashoffset 450px
  animation dash 1.5s ease forwards infinite
.svg-logo__o
  opacity 1
  fill none
  stroke $dark
  stroke-width 3.5px

@keyframes dash
  to
    stroke-dashoffset 0px

