@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.floor-container {
  width: 100%;
  height: 100%;
  text-align: center;
  transition: top 1s cubic-bezier(0.02, 0.63, 0.31, 1.04), left 1s cubic-bezier(0.02, 0.63, 0.31, 1.04), z-index 0.2s cubic-bezier(0.02, 0.63, 0.31, 1.04);
}
.floor-container.active svg {
  pointer-events: all;
}
.floor-container.drag svg {
  cursor: move;
}
.floor-container--zoomed svg .space-name {
  display: initial !important;
  transition: all 100ms ease;
  user-select: none;
}
.floor-container >>> svg {
  overflow: visible !important;
  margin: auto;
  transition: transform 0.2s ease;
}
@media screen and (max-width: 500px) {
  .floor-container >>> svg {
    transition: transform 0.1s linear;
  }
}
.floor-container >>> svg .space {
  transition: fill 0.2s ease;
  fill: #ddd9d3;
  stroke: #464749;
  stroke-width: 0.2;
}
.floor-container >>> svg .space.selected {
  opacity: 1;
  fill: #fff !important;
}
.floor-container >>> svg .wall {
  pointer-events: none;
  fill: #333;
  stroke-width: 0;
}
.floor-container >>> svg .door {
  fill: #ddd9d3;
  stroke: #000;
  stroke-width: 0.5px;
}
.floor-container >>> svg .window {
  fill: #e9e5e1;
  stroke: #e9e5e1;
}
/*# sourceMappingURL=src/app/components/floors/Floor2DMap.css.map */