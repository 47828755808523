























































































@import '~variables'

.title
  font-weight 400
  font-size 20px
  margin 20px 0 20px 0

.actions-container
  display flex
  flex-direction row
  gap 10px
  margin 20px 0 20px 0
  .return
    display grid
    padding-top 10px
    width fit-content
    text-transform uppercase

.content-container
  background-color white
  height fit-content
  border-radius 3px
  padding 20px

.o-label
  text-transform capitalize

.pointer
  cursor pointer

