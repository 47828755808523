@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.comments {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(9,30,66,0.31), 0 2px 4px -1px rgba(9,30,66,0.25);
}
.comments .title {
  padding: 16px;
  border-bottom: 1px solid #f4f4f4;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1;
}
.comments .comments-list {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  max-height: 200px;
}
.comments .comments-list .comment-item {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  margin-bottom: 8px;
  padding: 4px 8px;
  max-width: 350px;
  width: fit-content;
  border-radius: 10px;
  background-color: #e9e8e5;
}
.comments .comments-list .comment-item--me {
  margin-left: auto;
  background-color: #464749;
  color: #fff;
}
.comments .comments-list .comment-item--me .info {
  color: #c3c1ba !important;
}
.comments .comments-list .comment-item .message {
  margin-bottom: 4px;
  width: 100%;
  font-size: 1.1rem;
}
.comments .comments-list .comment-item .info {
  color: #939184;
  font-size: 0.8rem;
}
.comments .comments-list .comment-item .info .info-author {
  margin-right: 8px;
}
.comments .no-comment {
  padding: 16px;
  text-align: center;
  font-size: 1.1rem;
}
.send-comment {
  display: flex;
  flex-shrink: 0;
  padding: 16px;
  border-top: 1px solid #f4f4f4;
}
.send-comment .send-comment__input {
  flex: 8;
  margin-right: 8px;
  width: 100%;
}
.send-comment .comment-button {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
/*# sourceMappingURL=src/app/pages/ticket/components/ticket-comments.css.map */