@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.title {
  font-weight: 400;
  font-size: 20px;
  margin: 20px 0 20px 0;
}
.actions-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 20px 0 20px 0;
}
.actions-container .return {
  display: grid;
  padding-top: 10px;
  width: fit-content;
  text-transform: uppercase;
}
.content-container {
  background-color: #fff;
  height: fit-content;
  border-radius: 3px;
  padding: 20px;
}
.o-label {
  text-transform: capitalize;
}
.pointer {
  cursor: pointer;
}
/*# sourceMappingURL=src/app/pages/devices/DevicesDevice.css.map */