@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.timepicker {
  min-width: 200px;
}
.timepicker .timepicker__labels-row {
  display: flex;
  justify-content: center;
  padding: 5px 0;
  border-bottom: solid 1px #464749;
}
.timepicker .timepicker__labels-row .timepicker__label {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.1rem;
}
.timepicker .picker-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.timepicker .picker-container .hours,
.timepicker .picker-container .minutes {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 5px;
  width: 100%;
  height: 200px;
  font-size: 1.8rem;
}
@media screen and (max-width: 500px) {
  .timepicker .picker-container .hours,
  .timepicker .picker-container .minutes {
    justify-content: start;
  }
}
@media screen and (max-width: 500px) {
  .timepicker .picker-container .hours {
    overflow: scroll;
    height: 280px;
  }
}
.timepicker .picker-container .hour-input,
.timepicker .picker-container .minute-input {
  margin: 5px 0;
  padding: 2px 0 2px 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.timepicker .picker-container .hour-input--selected,
.timepicker .picker-container .minute-input--selected {
  border-radius: 5px;
  background-color: #fff !important;
  color: #000 !important;
}
.timepicker .picker-container .hour-input:hover,
.timepicker .picker-container .minute-input:hover {
  border-radius: 5px;
  background-color: #464749;
  color: #fff;
}
.timepicker .picker-container .hour-input--disabled,
.timepicker .picker-container .minute-input--disabled {
  background-color: #000 !important;
  color: #464749 !important;
}
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  background: #2f2f2f;
}
::-webkit-scrollbar-thumb {
  background: #828282;
}
::-webkit-scrollbar-thumb:hover {
  background: #b9b9b9;
}
/*# sourceMappingURL=src/app/components/inputs/time-picker.css.map */