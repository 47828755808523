
























































































@import '~variables'

.text-field
  display flex
  flex-direction column
  .o-input
    width 100%
  .input-container
    display flex
    &__suffix
      margin-left 10px
      font-weight bold
      align-self center
