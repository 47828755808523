


























































































































































































































































































@import '~variables'

.period-selector
  display flex
  flex-direction column
  justify-content center
  .container
    display flex
    flex-direction row
  .period
    margin 0 14px
    color $grey
    cursor pointer
    &:hover, &--selected
      position relative
      color black
      font-weight 700
      &:after
        position absolute
        right 0
        bottom -4px
        left 0
        height 2px
        background-color black
        content ''
  .calendar-drawer
    position absolute
    top 23px
    right 100%
    transition all 0.3s ease
    @media screen and (max-width: 500px)
      top 170px
      right 0
      left 0
      z-index 100
      margin 0 auto
  .calendar-drawer-alert
    position absolute
    top 100px
    right 45%
    transition all 0.3s ease
    @media screen and (max-width: 500px)
      top 170px
      right 0
      left 0
      z-index 100
      margin 0 auto

.date-input-container
  display flex
  flex-direction column
  justify-content left
  align-items center
  font-size $nfs-body
  .control-time__date-input
    padding $space-little
    font-weight $fw-bold
    cursor pointer
    user-select none
  .date-input__chevron
    transform rotate(-90deg)
  .date-input__hours
    padding $space-little
    color $dark
    font-weight $fw-light
    cursor pointer
    user-select none
  .selectedDrawer
    height 23px
    border-radius 4px
    background-color black
    color white

.date-input__chevron
  margin 0 14px
  width 20px
  height @width
  transform rotate(-90deg)

.fade-enter-active, .fade-leave-active
  opacity 1

.fade-enter, .fade-leave-to
  opacity 0
