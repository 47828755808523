








































































































































































@import '~variables'

$grid-border-dark = 1px solid #dfdfdf

.alert-simple
  cursor pointer
  border-radius $border-radius-smooth
  .label
    color grey
  .strong
    font-size $fs-h3
    font-weight 600
  .field
    padding 10px
    display flex
    flex-direction column
  .field:first-child
    border-top 0
  .field:last-child
    border-right 0

.low
  border 4px solid #d3eaff
.normal
  border 4px solid #fbe1be
.high
  border 4px solid #f29096
.resolved
  border 0
  background-color #dcdcdc

.o-label
  text-transform capitalize
  color lighten($dark, 20)
  margin-bottom 0

.grid-wrapper
  border-top $grid-border-dark
.grid-tile
  border-right $grid-border-dark
.device
  word-break break-all;
