




















































@import '~variables'

$state-blue = #6368df
$state-orange = #957000
$state-green = #398539

.ticket-status
  display flex
  align-items center
  padding 0
  width fit-content
  .state
    padding $space-little
    background-color transparent
    color lighten($dark, 40%)
    text-transform uppercase
    letter-spacing 0.5px
    font-weight 300
    font-size $fs-little
    cursor pointer
  .state:hover, .state--current
    border-radius $border-radius-smooth
    background-color $dark
    color white
    font-weight 700
    // &:hover

.separator-arrow
  margin 0 $space-1
  transform rotate(-90deg)
