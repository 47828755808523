





















@import '~variables'

.container
  display flex
  justify-content center
  align-items center
  margin auto
  padding-top 1%
  padding-bottom 1%
  .page
    width 1200px
    min-width 350px

