








































































































































































































@import '~variables'

.actions-container
  display flex
  flex-direction row
  gap 10px
  margin 20px 0 20px 0!important

.title
  font-weight 400
  font-size 20px
  margin 20px 0 20px 0

.flex
  display flex

.flex-row
  flex-direction row

.flex-wrap
  flex-wrap wrap

.justify-evenly
  justify-content space-evenly

.justify-center
  justify-content center

.p-10
  padding 10px

.p-20
  padding 20px

.mb-10
  margin-bottom 10px

.font-size-x-large
  font-size x-large

.content-action
  display flex
  margin 20px 0 20px 0

.content-container
  background-color white
  height fit-content
  border-radius 3px
  .table
    padding 20px
    font-weight 600
    font-size $fs-h2
    box-shadow none

