@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.row > div {
  padding: 5px 5px;
}
.no-padding {
  padding: 0;
}
.details .tabs-container {
  display: flex;
  flex-direction: column;
  max-width: 800px;
}
.details .ticket-container {
  background-color: #f3f1ef;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
}
.details .ticket-container .ticket-main {
  width: 100%;
}
.details .ticket-container .ticket-main .ticket-form {
  border: none;
}
.details .plan-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.details .plan-container .map-container {
  height: 100%;
}
.details .data-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding: 16px;
}
.details .data-container .periodSelector {
  margin-left: auto;
  width: fit-content;
  margin: 0 100px;
}
.details .data-container .date-info {
  display: flex;
  justify-content: center;
  padding: 8px;
  font-weight: 300;
  font-size: 1.1rem;
}
.details .message {
  display: grid;
  font-size: 1.6rem;
  place-items: center;
}
/*# sourceMappingURL=src/app/pages/alert/components/alert-details.css.map */