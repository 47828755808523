@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.alert-detail {
  display: flex;
  flex-direction: row;
  font-size: 1.3rem;
  gap: 3px;
  justify-content: space-evenly;
}
@media (max-width: 1000px) {
  .alert-detail {
    flex-direction: column;
  }
}
.alert-detail .sidebar {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 400px;
}
@media (max-width: 1000px) {
  .alert-detail .sidebar {
    max-width: none;
  }
}
.alert-detail .alert-data {
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: #fff;
  max-width: 900px;
  width: 100%;
  min-width: 700px;
}
@media (max-width: 1000px) {
  .alert-detail .alert-data {
    max-width: 730px;
    min-width: 300px;
  }
}
.message {
  display: grid;
  font-size: 1.6rem;
  place-items: center;
}
.actions-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 0 0 10px 0;
}
.actions-container .pointer {
  cursor: pointer;
}
.actions-container .pointer>* {
  margin-right: 4px;
}
.content-container {
  height: fit-content;
  border-radius: 3px;
}
.loader {
  max-width: 150px;
  width: 20%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
/*# sourceMappingURL=src/app/pages/alert/components/alert-details-layout.css.map */