@import './quasar.variables'
@import '~quasar-styl'

// @import '~quasar-addon-styl'

// === GLOBAL STYLE ===

// OASIS BUTTON - squared - black - bordered - shadows - hover - negative
.o-button
  padding $space-1 $space-2
  outline none
  border none
  border-radius $border-radius
  font-weight 700
  font-size $fs-body
  user-select none
  -webkit-border-radius @border-radius
  &--bordered
    padding 7px $space-2
    border 1px solid black
  &--squared
    border-radius $border-radius-smooth
  &--black
    background-color black !important
    color white
   &--dark
    background-color #373737 !important
    color white
  &--negative
    background-color $negative !important
    color white
  &--primary-gradient
    background linear-gradient(to right, darken($primary, 20%), darken($primary, 10%))
    color white
  &--shadows
    box-shadow $shadow-card
  &--hover
    cursor pointer
    transition all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86)
    &:hover
      box-shadow $shadow-elevation-2
    &:active
      box-shadow $shadow-elevation-1
  &--disabled
    opacity 0.6
    cursor not-allowed
    &:hover
      box-shadow none

.o-textarea
  width 100%
  height auto !important
  outline none
  resize none

// OASIS INPUT - inversed - negative
.o-input, .o-textarea
  padding 8px
  height $space-3
  border 1px solid black
  border-radius $border-radius-smooth
  box-shadow $shadow-elevation-1
  font-size $fs-h2
  transition box-shadow 0.3s ease
  &:focus
    box-shadow $shadow-elevation-2
  &--inversed
    border 1px solid white
    background-color transparent
    color white
  &--negative
    border 1px solid $negative
  &--faded
    border 1px solid $silver
    background-color $light
    &:hover, &:focus
      border 1px solid black
      background-color white

// OASIS INPUT - inversed - negative (class for quasar component)
.o-select
  padding 5px $space-1
  height $space-3
  border 1px solid black
  border-radius $border-radius-smooth
  font-size $fs-h2
  transition box-shadow 0.3s ease
  &:focus
    box-shadow $shadow-elevation-2
  &--negative
    border 1px solid $negative

.q-popover
  .q-list
    padding 0
    border 1px solid black !important
    border-radius $border-radius-smooth
    box-shadow $shadow-elevation-2
    .q-item
      font-size $fs-body
      &:hover
        background-color black !important
        color white
    .q-select-highlight
      background-color black !important
      color white

// OASIS LABEL
.o-label
  margin-bottom $space-1
  font-weight 400
  font-size $fs-h3

.o-sub-label
  margin-bottom $space-1
  color $grey
  font-size $fs-little

// OASIS GRID
.grid-wrapper
  display flex
  width 100%
  border-top $grid-border
  &--top
    border-top none

.grid-tile
  display flex
  flex-direction column
  align-items flex-start
  padding 20px
  border-right $grid-border

.grid-tile__label
  margin-bottom auto
  color lighten($dark, 40)

.cursor
  &--add-device
    cursor url('/assets/ui/device-add.svg') 10 10, crosshair
  &--close-device
    cursor url('/assets/ui/device-close.svg') 10 10, crosshair

// OASIS CARD
.o-card
  border-radius $border-radius-smooth
  box-shadow $shadow-card

// OASIS CHIP
.o-chip
  padding 4px
  border-radius 2px
  letter-spacing 0.5px
  line-height 0.9

.link--no-underline
  text-decoration none

// RESET
h1, h2, h3, h4
  margin 0
  line-height 1

// OLD GLOBAL-STYLE inherited from vue-qarnot package
body, html
  position relative
  height 100%
  background-color #ded9d2

[v-cloak]
  display none

.flex, .row, .column
  flex-wrap nowrap

.q-datetime-selector
  .flex, .row, .column
    flex-wrap wrap

.q-if:before, .q-if:after
  content none

.q-datetime
  border none !important
  background $black !important

.q-datetime-clock-position.active > span
  color $black !important

input
  padding 8px
  outline none
  border 2px solid $light
  border-radius 4px

button, a.button
  font-weight 700

li a
  text-decoration none

a:-webkit-any-link
  text-decoration none

ul, li
  margin 0
  list-style-type none
  margin-block-start 0
  margin-block-end 0
  padding-inline-start 0

.vdatetime-input
  padding 5px
  border none
  background-color transparent
  cursor pointer

.theme-dark .vdatetime-popup__header, .theme-dark .vdatetime-calendar__month__day--selected > span > span, .theme-dark .vdatetime-calendar__month__day--selected:hover > span > span
  background $dark

.theme-dark .vdatetime-year-picker__item--selected, .theme-dark .vdatetime-time-picker__item--selected, .theme-dark .vdatetime-popup__actions__button
  color $dark

::-webkit-scrollbar
  width 10px

::-webkit-scrollbar-track
  background #cacaca

::-webkit-scrollbar-thumb
  background #828282

::-webkit-scrollbar-thumb:hover
  background #000