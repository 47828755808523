






































































































































































































































@import '~variables'


.details
  display flex
  justify-content center
  .content
    text-align left
    display flex
    background-color $light
    flex-direction column
    gap 1px
    .item
      background-color white
      min-width 200px
      text-align left
      justify-content left
      padding 15px
      .label
        color #acadaf
        font-size 14px
        margin-bottom 10px
      .high
        background-color #FF3D00
        color white
      .normal
        background-color #FFB800
        color white
      .low
        background-color #8DCC26
        color white
    .title
      border-top none
      text-align center
      font-size $fs-h1
  .history
    text-align center
    padding 50px
    .history-button
      color white
      background #074EE8
      position static
      left 12px
      top 7px
    .trigger
      padding 40px

@media (max-width: 1000px)
  .content
    display flex
    flex-direction row
    flex-wrap wrap


